import settings from '@/constants/constants';
import { FF_ENABLE_PATIENT_PROFILE_UPLIFT } from '@/constants/featureFlags';
import { useOrderFormMeta } from '@/context/order/OrderFormMeta';
import useFeatureFlags from '@/hooks/useFeatureFlags';
import { AuthService } from '@/services/authentication.service';
import generateTrackingLink from '@/utils/patient/trackingLink';
import { Box, Link as MUILink, Skeleton, TextField, styled } from '@mui/material';
import { Link } from 'react-router-dom';

const TitleStyle = styled(Box)(({ theme }) => ({
  margin: theme.spacing(4, 0)
}));

const InputStyle = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  gap: theme.spacing(2)
}));

export function PatientOrderDetails() {
  const { flags } = useFeatureFlags();
  const { shopifyDiscountsURL } = settings;

  const { order, error, isLoading, isNewOrder } = useOrderFormMeta();

  const PatientFullName = `${order?.Patient?.PatientUser?.first_name} ${order?.Patient?.PatientUser?.last_name}`;
  const ShopifyOrderConfirmationNumber = order?.orderManagementShopify?.shopify_order_confirmation_number;
  const isTrackingID = order?.shipment_tracking_id && order?.shipment_carrier;

  if (isLoading) {
    return (
      <Box data-testid="loading">
        <TitleStyle>Patient Details</TitleStyle>
        <InputStyle>
          <Skeleton width="50%" height={50} variant="rectangular" />
          <Skeleton width="50%" height={50} variant="rectangular" />
        </InputStyle>

        <TitleStyle>Order Details</TitleStyle>
        <InputStyle>
          <Skeleton width="25%" height={50} variant="rectangular" />
          <Skeleton width="25%" height={50} variant="rectangular" />
          <Skeleton width="25%" height={50} variant="rectangular" />
          <Skeleton width="25%" height={50} variant="rectangular" />
        </InputStyle>
      </Box>
    );
  }

  //   NOTE: Need to update once we get error state design
  if (error) {
    return <div>Error: {error.message}</div>;
  }

  return (
    <>
      <TitleStyle>Patient Details</TitleStyle>
      <InputStyle>
        <TextField
          label="Patient ID"
          fullWidth
          InputProps={{
            readOnly: true,
            startAdornment: order?.Patient?.patient_code ? (
              <MUILink
                component={Link}
                to={`/${flags[FF_ENABLE_PATIENT_PROFILE_UPLIFT] ? 'patient' : 'onboard'}/${order?.Patient?.id}`}
              >
                {order?.Patient?.patient_code}
              </MUILink>
            ) : (
              '-'
            )
          }}
          InputLabelProps={{ shrink: true }}
        />
        <TextField
          label="Patient Full Name"
          fullWidth
          InputLabelProps={{ shrink: true }}
          value={PatientFullName}
          InputProps={{
            readOnly: true
          }}
        />
      </InputStyle>
      {AuthService.isAdmin() && !isNewOrder && (
        <>
          <TitleStyle>Order Details</TitleStyle>
          <InputStyle>
            <TextField
              label="Payment Status"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={order?.payment_status}
              InputProps={{
                readOnly: true
              }}
            />

            <TextField
              label="Shopify Order Confirmation #"
              fullWidth
              InputLabelProps={{ shrink: true }}
              InputProps={{
                readOnly: true,
                startAdornment: ShopifyOrderConfirmationNumber ? (
                  <MUILink
                    component={Link}
                    to={`${shopifyDiscountsURL}/orders?query=${ShopifyOrderConfirmationNumber}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {ShopifyOrderConfirmationNumber}
                  </MUILink>
                ) : (
                  '-'
                )
              }}
            />

            <TextField
              label="Consignment ID"
              fullWidth
              InputLabelProps={{ shrink: true }}
              value={order?.consignmentID}
              InputProps={{
                readOnly: true
              }}
            />
            <TextField
              label="Tracking ID"
              fullWidth
              InputLabelProps={{ shrink: true }}
              InputProps={{
                readOnly: true,
                startAdornment: isTrackingID ? (
                  <MUILink
                    component={Link}
                    to={generateTrackingLink(order?.shipment_carrier ?? '', order?.shipment_tracking_id ?? '') as any}
                  >
                    {order?.trackingID}
                  </MUILink>
                ) : (
                  '-'
                )
              }}
            />
          </InputStyle>
        </>
      )}
    </>
  );
}

export default PatientOrderDetails;
