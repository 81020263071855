import AdminPageLayout from '@/components/layout/AdminPageLayout';
import { OrderActions } from '@/components/order/OrderActions/OrderActions';
import OrderFormButtons from '@/components/order/OrderFormButtons';
import { PatientOrderDetails } from '@/components/order/PatientOrderDetails/PatientOrderDetails';
import { OrderMetaProvider, useOrderFormMeta } from '@/context/order/OrderFormMeta';
import type { Order } from '@/types/order.types';
import { Logger } from '@/utils/logger';
import { BrandThemeProvider, montu } from '@montugroup/themes';
import { FormProvider, useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';

const logger = new Logger('Order');

const getFormDefaultValue = (orderData: Order | undefined) => {
  return {
    priority: orderData?.priority ?? false
  };
};

export function Order() {
  const navigate = useNavigate();
  const { order, error } = useOrderFormMeta();

  const formMethods = useForm({
    values: getFormDefaultValue(order)
  });

  if (error) {
    // todo - awaiting UX input for error state
    logger.error('Failed to load order', error);
    navigate('/404');
  }

  return (
    <BrandThemeProvider theme={montu}>
      <OrderMetaProvider>
        <FormProvider {...formMethods}>
          <AdminPageLayout title="Order" Actions={<OrderActions />} fullWidth={false}>
            <PatientOrderDetails />
            <OrderFormButtons />
          </AdminPageLayout>
        </FormProvider>
      </OrderMetaProvider>
    </BrandThemeProvider>
  );
}
